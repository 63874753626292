// Header styling

#header {
	@extend .default-animation;
	@extend .padding-horiz;
	position: absolute;
	z-index: 10;
	width: 100%;
	height: $header-height;
	background-color: rgba(255,255,255,0.90);
	box-shadow: 0 0.05rem 1rem rgba(0,0,0, 0.15);


	// set heights for vertical centering
	> .grid, #logo, #navbar {
		height: 100%;
	}

	#logo {
		float: left;
		h3 {
			@extend .default-animation;
			@extend %vertical-align;
			font-size: 2rem;
			line-height: 2rem;
			margin: 0;
			text-transform: uppercase;
			a {
				color: $core-text;
			}
		}
	}

	#navbar {
		font-size: $core-font-size - 0.1rem;
		ul {

			margin: 0;
			padding: 0;
			list-style: none;

			&.navigation {
				@extend %vertical-align;
				display: inline-block;
				
				float: right;
				li {
					float: left;
					position: relative;

					a {
						font-family: $font-family-header;
						display: inline-block;
						padding: 0.3rem 0.8rem;

						&:before, &:after {
							content: "";
							position: absolute;
							width: 100%;
							height: 1px;
							bottom: 0;
							left: 0;
							background-color: $core-accent;
							visibility: hidden;
							@include transform(scaleX(0));
							@include transition(all 0.2s ease);
						}

						&:hover:before {
							visibility: visible;
							@include transform(scaleX(0.75));
						}

						&.active:after {
							top: 0;
							visibility: visible;
							@include transform(scaleX(0.75));
						}
					}

					&.active {
						a:after {
							top: 0;
							visibility: visible;
							@include transform(scaleX(0.75));
						}
					}

					// Dropdown Menu Styles
					ul {
						display: none;
						padding: 0;
						box-shadow: 0 0.05rem 1rem rgba(0,0,0, 0.15) !important;
					}

					ul ul {
						left: 100%;
						top: 0;
					}	


					&:hover {
						& > ul {
							display: block;
							position: absolute;
							background: rgba($white, 0.9);
							width: 10rem;
						}

						li {
							float: none;
							margin: 0;
							padding: 0;

							a {
								padding: 0.5rem 0.8rem;
								display: block;

								&:before, &:after {
									display: none;
								}
							}

							&.active {
								& > a {
									background: $core-accent;
									color: $white;
								}
							}
						}
					}
				}
				@include breakpoint(desktop-only) {
					display: none;
				}
			}	
		}

		.panel-activation {
			@extend %vertical-align;
            padding: 1rem;
			display: none;
			font-size: 1.8rem;
			cursor: pointer;
			float: right;
			@include breakpoint(desktop-only) {
				display: inline-block;
			}
		}

	}

}

// Header Image
.header-image {
    &.fullwidth {
        #body {
            padding-left: 0;
            padding-right: 0;

            >.listing-row {
                padding-left: $padding-horiz;
                padding-right: $padding-horiz;
            }
        }
    }
    .listing-row:last-child {
    	margin-bottom: 2rem;
    }

    #body {
        .flush-top {
            margin-top: - $header-height - $padding-vert - 1.5rem;
            padding-top: $header-height + 4rem;
        }
    }

    #breadcrumbs {
    	margin-top: 1rem;
    }

    #header {
        background-color: rgba($header-text,0);
        box-shadow: none;

        #logo h3, #logo a {
            color: $header-text;
        }
        a, .menu-btn {
            color: $header-text;
        }
        a:before, a:after {
        	background-color: rgba($header-text,0.7) !important;
        }

        #navbar ul.navigation  {

            ul li a {

                color: $core-accent;
                &:hover {
                    color: darken($core-accent, 20%);
                }
            }
        }
    }
}
